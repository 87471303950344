import {
  CmsGallery,
  CmsGalleryItem,
  CmsGalleryLayoutEnum,
} from 'framework/strapi/types'
import React, { FC } from 'react'
import { Dictionary } from 'utils/dictionary'
import { GallerySettings } from './Gallery'
import GalleryItem from './GalleryItem'

interface Props extends Pick<CmsGallery, 'zoom' | 'items'> {
  layout: CmsGalleryLayoutEnum
  gallerySettings: GallerySettings
  css: Dictionary<string>
  logos?: string[]
  setLightBoxIsOpen: (state: boolean, index: number) => any
  priorityItems?: (item: CmsGalleryItem, index: number) => boolean
}

const GalleryGrid: FC<Props> = ({
  layout,
  gallerySettings,
  css,
  zoom,
  items,
  logos,
  setLightBoxIsOpen,
  priorityItems,
}) => {
  if (!items?.[0]?.asset && !items?.[0]?.asset_canto && !items?.[0]?.externalVideo) {
    return null
  }
  return (
    <div
      className={`${
        layout === 'Product' &&
        'xl:flex-1 xl:max-w-lg xl:w-[576px] 2xl:max-w-xl'
      }`}
    >
      <div className={css.gridCell}>
        <GalleryItem
          css={css}
          zoom={zoom!}
          gallerySettings={gallerySettings}
          index={0}
          setLightBoxIsOpen={setLightBoxIsOpen}
          item={items[0]}
          logos={logos}
          nItems={items.length}
          priorityItems={priorityItems}
        />
      </div>
    </div>
  )
}

export default GalleryGrid
