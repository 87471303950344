import { PlayIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { Dictionary } from '@reduxjs/toolkit'
import cn from 'classnames'
import CmsImage from 'components/common/CmsImage'
import Logos from 'components/common/Logos'
import { CmsGalleryItem } from 'framework/strapi/types'
import React, { FC, useMemo } from 'react'
import parseYoutube from 'utils/parseYoutube'
import { GallerySettings } from './Gallery'
import { toStrapiFileComponent } from 'utils/canto'

interface Props {
  item: CmsGalleryItem
  logos?: string[]
  css: Dictionary<string>
  zoom?: boolean
  gallerySettings: GallerySettings
  setLightBoxIsOpen: (state: boolean, index: number) => any
  index: number
  nItems: number
  priorityItems?: (item: CmsGalleryItem, index: number) => boolean
}

const GalleryItem: FC<Props> = ({
  css,
  zoom,
  gallerySettings,
  index,
  setLightBoxIsOpen,
  item,
  nItems,
  logos,
  priorityItems,
}) => {
  css.media = 'object-cover'

  if (zoom) {
    css.media =
      'object-cover bg-neutral-2 transition-transform duration-700 lg:group-hover:scale-103'
  }

  const isYoutube =
    item.externalVideo?.includes('youtu.be') ||
    item.externalVideo?.includes('youtube')

  const isVimeo = item.externalVideo?.includes('vimeo')
  const itemExternalVideo = isYoutube
    ? `https://www.youtube.com/embed/${parseYoutube(
        item.externalVideo ?? '',
      )}?si=InolnpSwk2hWcEuP&amp;controls=0`
    : isVimeo
      ? `https://player.vimeo.com/video/${item.externalVideo?.replace(
          'https://vimeo.com/',
          '',
        )}?h=64eebfb4cc&title=0&byline=0&portrait=0`
      : item.externalVideo

  let asset = item.thumbnail || item.asset
  let asset_canto = item.thumbnail_canto || item.asset_canto

  if (item.externalVideo) {
    asset = {
      id: item.externalVideo,
      __component: 'cms.gallery-item',
      name: '',
      alternativeText: '',
      caption: '',
      formats: {
        large: {
          mime: 'image/jpeg',
          url: item.externalVideo,
          height: 0,
          width: 0,
          name: 'embedVideo',
        },
      },
      provider: '',
      url: itemExternalVideo ?? '',
      mime: isYoutube || isVimeo ? 'embed/video' : 'video/mp4',
      width: 0,
      height: 0,
    }
  }

  const isVideo = useMemo(() => {
    const type = item.externalVideo
      ? asset?.mime.split('/')[0]
      : item.asset?.mime.split('/')[0]
    return type === 'video' || type === 'normalvideo'
  }, [asset?.mime, item.asset?.mime, item.externalVideo])

  const isClickable =
    (zoom || (isVideo && item.thumbnail)) && !(isYoutube || isVimeo)

  const isVideoClickable =
    item.asset?.url?.includes('youtu.be') ||
    item.asset?.url?.includes('youtube') ||
    item.asset?.url?.includes('vimeo')

  const priority = useMemo(
    () => (priorityItems ? priorityItems(item, index) : false),
    [index, item, priorityItems],
  )

  if (!item.asset && !item.asset_canto && !item.externalVideo) return null

  return (
    <>
      <div
        className={cn(
          'relative',
          css[`mediaWrapper${index}`]
            ? css[`mediaWrapper${index}`]
            : css.mediaWrapper,
        )}
      >
        {asset_canto && (
          <CmsImage
            alt={asset_canto.name}
            image={toStrapiFileComponent(asset_canto)}
            fill
            className={css.media}
            priority={priority}
          />
        )}
        {asset && !asset_canto && (
          <CmsImage
            alt={asset.name}
            image={asset}
            fill
            className={css.media}
            priority={priority}
          />
        )}
        {gallerySettings.counter && nItems > 1 && (
          <div className={css.emblaCounterWrapper}>
            <div className="absolute bottom-4 right-4 lg:bottom-6 lg:right-6 flex flex-shrink-0 h-6 lg:h-7 items-center gap-x-0.5 px-2 lg:px-3 lg:pb-0.5 rounded-2xl font-bold text-xs lg:text-sm bg-white text-primary">
              <span>{index + 1}</span>
              <span>/</span>
              <span>{nItems}</span>
            </div>
          </div>
        )}
        {logos && (
          <div className="flex items-end p-4 pointer-events-none lg:p-6">
            <Logos logos={logos} />
          </div>
        )}
        {isClickable && (
          <div
            onClick={() => {
              return setLightBoxIsOpen(true, index)
            }}
            className="absolute flex items-center justify-center w-full h-full cursor-pointer"
          >
            {isVideoClickable ? (
              <PlayIcon className="text-white transition-opacity duration-200 opacity-50 w-14 h-14 group-hover:opacity-90" />
            ) : (
              <div className="flex items-center justify-center transition-opacity duration-200 bg-white rounded-full opacity-0 w-14 h-14 group-hover:opacity-60">
                <MagnifyingGlassIcon className="w-6 h-6 text-black" />
              </div>
            )}
          </div>
        )}
      </div>
      {item.caption && !gallerySettings.excludeCaption && (
        <div className={css.captionWrapper}>
          <div className={css.caption}>{item.caption}</div>
        </div>
      )}
    </>
  )
}

export default GalleryItem
