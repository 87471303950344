// TODO have settings stored in tailwind.config ?
const breakPoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}

export type BreakPointTypes = keyof typeof breakPoints

// Return Breakpoint in pixels
export const breakPoint = (bp: BreakPointTypes) => {
  return breakPoints[bp]
}

// Check if browser width > breakpoint, return bool
export const breakPointMin = (bp?: BreakPointTypes): boolean => {
  if (typeof window === 'undefined' || !bp) return false

  return window.innerWidth >= breakPoint(bp)
}
