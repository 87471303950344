// Check if touch device
const isTouchDevice = () => {
  if (typeof window === 'undefined') return

  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  )
}

export default isTouchDevice
