import cn from 'classnames'
import { ContainerLayout } from 'components/ui/Container'
import { CmsGallery, CmsGalleryItem } from 'framework/strapi/types'
import dynamic from 'next/dynamic'
import React, { FC, useMemo, useState } from 'react'
import { BreakPointTypes } from 'utils/breakpoints'
import GalleryCarousel from './GalleryCarousel'
import GalleryGrid from './GalleryGrid'
import { getStrapiFileMediaType } from 'utils/get-strapi-file-mime-type'

const LightBox = dynamic(() => import('./LightBox'), { ssr: false })

interface Props extends CmsGallery {
  container?: ContainerLayout
  logos?: string[]
  dots?: boolean
  thumbs?: boolean
  counter?: boolean
  colorTheme: string | null
  priorityItems?: (item: CmsGalleryItem, index: number) => boolean
}

export interface GallerySettings {
  isCarousel: boolean
  excludeCaption: boolean
  dots: boolean
  thumbs: boolean
  counter: boolean
  carousel: {
    disableBreakPoint?: BreakPointTypes
    buttonVisible: string
    buttonStyle: string
  }
}

const Gallery: FC<Props> = ({
  container,
  items,
  tw_aspectRatio,
  tw_lightBoxAspectRatio,
  layout,
  logos,
  zoom,
  dots,
  thumbs,
  counter,
  colorTheme,
  captionStyle,
  priorityItems,
}) => {
  // Lightbox
  const [lightBoxIsOpen, setLightBoxIsOpen] = useState(false)
  const [lightBoxStartIndex, setLightBoxStartIndex] = useState(0)

  const handleLightBoxIsOpen = (state: boolean, index: number) => {
    setLightBoxStartIndex(index)
    setLightBoxIsOpen(state)
  }

  // Aspect Ratio
  const aspectRatio = tw_aspectRatio || 'aspect-w-16 aspect-h-9'

  const gallerySettings: GallerySettings = {
    isCarousel: false,
    excludeCaption: false,
    dots: dots || false,
    thumbs: thumbs || false,
    counter: counter || false,
    carousel: {
      disableBreakPoint: undefined,
      buttonVisible: 'yes', // changed from deviceResponsive to work with large touch screens
      buttonStyle: 'default',
    },
  }

  // Layout: Default
  const css: any = {
    grid: '',
    gridCell: '',
    emblaViewport: 'embla__viewport relative overflow-hidden',
    emblaContainer: 'embla__container relative flex select-none',
    slide: 'embla__slide relative flex-shrink-0 w-full',
    mediaWrapper: cn('group overflow-hidden', aspectRatio),
    captionWrapper: 'my-3 lg:my-4',
    caption: 'font-medium text-sm',
    emblaCounterWrapper: '',
    emblaDotsWrapper: '',
    emblaThumbsWrapper: '',
  }

  switch (colorTheme) {
    case 'Dark':
      css.caption = cn(css.caption, 'text-white')
      break
    default:
      if (
        captionStyle === 'Default' ||
        captionStyle === null ||
        captionStyle === undefined
      )
        css.caption = cn(css.caption, 'text-primary')
      else css.caption = cn(css.caption, 'text-[#aaaaaa]')
  }

  // Caption Wrapper based on Parent Container
  css.captionWrapper = cn(css.captionWrapper, {
    'px-6 md:px-14 lg:px-20': container === 'Full',
    'px-6 md:px-0': container === 'FullFluid',
    'md:px-14 lg:px-20': container === 'FluidFull',
  })

  const showLightbox = useMemo(() => {
    if (!items) return false
    if (zoom) return true
    for (const i of items) {
      if (i.asset && i.thumbnail) {
        const type = getStrapiFileMediaType(i.asset)
        if (type === 'video' || type === 'normalvideo') return true
      }
    }
    return false
  }, [items, zoom])

  if (!items) return null

  if (items.length > 1) gallerySettings.isCarousel = true

  gallerySettings.carousel.buttonStyle = 'inside'

  // TODO
  //   if (captionStyle === 'story') {
  //     css.caption = 'font-medium font-sans text-sm text-neutral-5'
  //   }

  if (container === 'Full') {
    css.emblaDotsWrapper = 'px-6 md:px-14 lg:px-20'
    css.emblaThumbsWrapper = 'px-6 md:px-14 lg:px-20'
  }

  if (layout === 'Product') {
    gallerySettings.carousel.buttonVisible = 'deviceResponsive'
  }

  if (layout === 'NoButtons') {
    gallerySettings.carousel.buttonVisible = 'no'
  }

  if (layout === 'OneColumn') {
    gallerySettings.isCarousel = true
    gallerySettings.carousel.disableBreakPoint = 'lg'
    gallerySettings.carousel.buttonStyle = 'default'
    css.emblaViewport = 'embla__viewport relative lg:overflow-hidden'
    css.emblaContainer =
      'embla__container relative flex select-none -ml-3 md:-ml-4 lg:ml-0 lg:grid lg:grid-cols-12 lg:gap-5'
    css.slide =
      'embla__slide relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0 lg:col-span-12'
    css.emblaCounterWrapper = 'md:hidden'
    css.emblaDotsWrapper = 'lg:hidden'
    css.emblaThumbsWrapper = 'lg:hidden'
  }

  if (layout === 'TwoColumns') {
    gallerySettings.isCarousel = true
    gallerySettings.carousel.disableBreakPoint = 'lg'
    gallerySettings.carousel.buttonStyle = 'default'
    css.emblaViewport = 'embla__viewport relative lg:overflow-hidden'
    css.emblaContainer =
      'embla__container relative flex select-none -ml-3 md:-ml-4 lg:ml-0 lg:grid lg:grid-cols-12 lg:gap-5'
    css.slide =
      'embla__slide relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0 lg:col-span-6'
    css.emblaCounterWrapper = 'md:hidden'
    css.emblaDotsWrapper = 'lg:hidden'
    css.emblaThumbsWrapper = 'lg:hidden'
  }

  if (layout === 'ThreeColumns') {
    gallerySettings.isCarousel = true
    gallerySettings.excludeCaption = true
    gallerySettings.carousel.disableBreakPoint = 'lg'
    gallerySettings.carousel.buttonStyle = 'default'
    css.emblaViewport = 'embla__viewport relative lg:overflow-hidden'
    css.emblaContainer =
      'embla__container relative flex select-none -ml-3 md:-ml-4 lg:ml-0 lg:grid lg:grid-cols-12 lg:gap-0.5'
    css.slide =
      'embla__slide relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0 lg:col-span-4'
    css.emblaCounterWrapper = 'md:hidden'
    css.emblaDotsWrapper = 'lg:hidden'
    css.emblaThumbsWrapper = 'lg:hidden'
  }

  if (layout === 'Curated') {
    if (items.length > 5) {
      // Limit media length, Max 5
      items.length = 5
    }

    gallerySettings.isCarousel = true
    gallerySettings.excludeCaption = true
    gallerySettings.carousel.disableBreakPoint = 'lg'
    gallerySettings.carousel.buttonStyle = 'default'
    css.emblaViewport = 'embla__viewport relative lg:overflow-hidden'
    css.emblaContainer =
      'embla__container relative flex select-none -ml-3 md:-ml-4 lg:ml-0 lg:grid lg:grid-cols-12 lg:grid-rows-12 lg:gap-5'
    css.slide =
      'embla__slide relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0 lg:col-start-1 lg:col-end-7 lg:row-start-1 lg:row-end-13'
    css.slide1 =
      'embla__slide group relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0 lg:col-start-7 lg:col-end-10 lg:row-start-1 lg:row-end-5'
    css.slide2 =
      'embla__slide group relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0 lg:col-start-7 lg:col-end-10 lg:row-start-5 lg:row-end-13'
    css.slide3 =
      'embla__slide group relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0 lg:col-start-10 lg:col-end-13 lg:row-start-1 lg:row-end-8'
    css.slide4 =
      'embla__slide group relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:flex-none lg:w-auto lg:p-0 lg:col-start-10 lg:col-end-13 lg:row-start-8 lg:row-end-13'
    css.mediaWrapper1 = cn(
      'group lg:h-full lg:pb-0 overflow-hidden',
      aspectRatio,
    )
    css.mediaWrapper2 = cn(
      'group lg:h-full lg:pb-0 overflow-hidden',
      aspectRatio,
    )
    css.mediaWrapper3 = cn(
      'group lg:h-full lg:pb-0 overflow-hidden',
      aspectRatio,
    )
    css.mediaWrapper4 = cn(
      'group lg:h-full lg:pb-0 overflow-hidden',
      aspectRatio,
    )
    css.emblaCounterWrapper = 'lg:hidden'
    css.emblaDotsWrapper = 'lg:hidden'
    css.emblaThumbsWrapper = 'lg:hidden'
  }

  if (layout === 'Wide') {
    gallerySettings.isCarousel = true
    gallerySettings.carousel.buttonStyle = 'inside'
    css.emblaViewport = 'embla__viewport relative lg:overflow-hidden_'
    css.emblaContainer =
      'embla__container relative flex select-none -ml-3 md:-ml-4 lg:-ml-5'
    css.slide =
      'embla__slide relative flex-shrink-0 w-11/12 pl-3 md:w-1/2 md:pl-4 lg:w-1/3 lg:pl-5'
  }

  // Lightbox Aspect Ratio
  const lightBoxAspectRatio = tw_lightBoxAspectRatio
    ? tw_lightBoxAspectRatio
    : aspectRatio

  return (
    <>
      {showLightbox && (
        <LightBox
          items={items}
          aspectRatio={lightBoxAspectRatio}
          isOpen={lightBoxIsOpen}
          setIsOpen={handleLightBoxIsOpen}
          startIndex={lightBoxStartIndex}
        />
      )}
      {gallerySettings.isCarousel ? (
        <GalleryCarousel
          layout={layout}
          priorityItems={priorityItems}
          gallerySettings={gallerySettings}
          css={css}
          aspectRatio={aspectRatio}
          zoom={zoom}
          items={items}
          logos={logos}
          setLightBoxIsOpen={handleLightBoxIsOpen}
        />
      ) : (
        <GalleryGrid
          layout={layout}
          priorityItems={priorityItems}
          gallerySettings={gallerySettings}
          css={css}
          zoom={zoom}
          items={items}
          logos={logos}
          setLightBoxIsOpen={handleLightBoxIsOpen}
        />
      )}
    </>
  )
}

export default Gallery
