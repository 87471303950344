import CmsImage from 'components/common/CmsImage'
import { StrapiFileComponent } from 'framework/strapi/types'
import { FC, MouseEventHandler } from 'react'
import translate from 'utils/translate'

interface Props {
  selected: boolean
  image: StrapiFileComponent
  onClick?: MouseEventHandler<HTMLButtonElement>
  layout: any
}

const CarouselThumb: FC<Props> = ({ selected, image, onClick, layout }) => {
  return (
    <div
      className={`embla-thumb relative flex-shrink-0 w-14 md:w-20 xl:w-24 2xl:w-32 ${
        layout === 'Product' && 'xl:flex xl:items-stretch'
      }`}
    >
      <button
        onClick={onClick}
        className="block w-full embla__slide__inner embla__slide__inner--thumb"
        type="button"
        aria-label={
          image.alternativeText || image.name || translate('galleryThumbnail')
        }
      >
        <div className="relative overflow-hidden aspect-w-1 aspect-h-1">
          <CmsImage
            alt={image.alternativeText}
            size="4x5"
            image={image}
            className="object-cover transition-transform duration-700 lg:hover:scale-103"
            isThumbnail={true}
            width="90"
            height="90"
          />
        </div>
      </button>
      <div
        className={`bg-primary w-1/2 h-1 mt-1 ${
          layout === 'Product' && 'xl:w-1 xl:h-auto xl:mt-0'
        } ${selected ? 'opacity-100' : 'opacity-0'}`}
      ></div>
    </div>
  )
}

export default CarouselThumb
