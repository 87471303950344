import {
  DotButton,
  NextButton,
  PrevButton,
} from 'components/common/CarouselButtons'
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react'
import {
  CmsGallery,
  CmsGalleryItem,
  CmsGalleryLayoutEnum,
} from 'framework/strapi/types'
import React, { FC, useCallback, useEffect, useState, useRef } from 'react'
import { breakPointMin } from 'utils/breakpoints'
import { Dictionary } from 'utils/dictionary'
import isTouchDevice from 'utils/is-touch-device'
import { GallerySettings } from './Gallery'
import CarouselThumb from './CarouselThumb'
import GalleryItem from './GalleryItem'
import { ContainerLayout } from 'components/ui/Container'
import type { AxisOptionType } from 'embla-carousel/components/Axis'

interface Props extends Pick<CmsGallery, 'zoom' | 'items'> {
  layout: CmsGalleryLayoutEnum
  gallerySettings: GallerySettings
  logos?: string[]
  css: Dictionary<string>
  aspectRatio: string
  setLightBoxIsOpen: (state: boolean, index: number) => any
  parentContainer?: ContainerLayout
  priorityItems?: (item: CmsGalleryItem, index: number) => boolean
}

const emblaOptions: Dictionary<EmblaOptionsType> = {
  disableCarousel: {
    align: 'start',
    watchDrag: false,
    loop: false,
  },
  touchDevice: {
    align: 'start',
    containScroll: 'trimSnaps',
    watchDrag: false,
    dragFree: false,
    loop: true,
  },
  desktopDevice: {
    align: 'start',
    containScroll: 'trimSnaps',
    watchDrag: false,
    dragFree: false,
    loop: true,
  },
}

const GalleryCarousel: FC<Props> = ({
  layout,
  gallerySettings,
  css,
  zoom,
  items,
  logos,
  setLightBoxIsOpen,
  priorityItems,
}) => {
  // Initialize Carousel
  const [viewportRef, embla] = useEmblaCarousel()

  // Initialize Buttons
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  // Button Visibility
  const [btnVisible, setBtnVisible] = useState(false)

  // Button Navigation
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  // Initialize Dot Buttons
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  // Initialize Thumbs
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
    axis: 'y',
  })

  const onThumbClick = useCallback(
    (index: number) => {
      if (!embla || !emblaThumbs) return
      embla.scrollTo(index)
    },
    [embla, emblaThumbs],
  )

  useEffect(() => {
    if (!embla) return

    const onSelect = () => {
      setPrevBtnEnabled(embla.canScrollPrev())
      setNextBtnEnabled(embla.canScrollNext())
      setSelectedIndex(embla.selectedScrollSnap())
      setScrollSnaps(embla.scrollSnapList())
      if (!emblaThumbs) return
      emblaThumbs.scrollTo(embla.selectedScrollSnap())
    }

    const setEmblaOptions = (): Partial<EmblaOptionsType> => {
      //Set visibililty for buttons
      if (gallerySettings.carousel.buttonVisible === 'deviceResponsive') {
        setBtnVisible(!isTouchDevice())
      } else if (gallerySettings.carousel.buttonVisible === 'yes') {
        setBtnVisible(true)
      } else {
        setBtnVisible(false)
      }

      if (breakPointMin(gallerySettings.carousel.disableBreakPoint)) {
        setBtnVisible(false)
        return emblaOptions.disableCarousel!
      }

      if (isTouchDevice()) return emblaOptions.touchDevice!
      return emblaOptions.desktopDevice!
    }

    embla.reInit(setEmblaOptions()) // Initialize Embla Options
    onSelect() // Enable/Disable Prev and Next Buttons, Update Dot Buttons
    embla.on('select', onSelect) // Enable/Disable Buttons on navigation

    embla.on('resize', () => {
      embla.reInit(setEmblaOptions()) // Update Embla Options
      onSelect() // Enable/Disable Buttons
    })

    // Thumbs
    if (!emblaThumbs) return

    // If gallery layout === 'Product' => Adjust Thumbs based on device size
    const setEmblaThumbsOptions = (): Partial<EmblaOptionsType> => {
      // console.log('set embla thumbs opts')
      const axisOption: AxisOptionType =
        layout === 'Product' && breakPointMin('xl') ? 'y' : 'x'

      // Set Thumbs Wrapper height matching Gallery Image Height
      if (layout === 'Product' && breakPointMin('xl')) {
        // console.log('embla height')
        thumbsWrapperRef.current.style.height =
          galleryImageRef.current.offsetHeight + 'px'
      } else {
        thumbsWrapperRef.current.style.height = null
      }

      // Embla Thumbs Options
      return {
        containScroll: 'keepSnaps',
        dragFree: true,
        axis: axisOption,
      }
    }

    // Initialize Embla Thumbs Options
    emblaThumbs.reInit(setEmblaThumbsOptions())

    emblaThumbs.on('resize', () => {
      emblaThumbs.reInit(setEmblaThumbsOptions())
    })
  }, [embla, emblaThumbs, gallerySettings, layout])

  // Refs for thumbs wrapper height
  const galleryImageRef: any = useRef()
  const thumbsWrapperRef: any = useRef()

  if (!items) return null

  return (
    <div className={`${layout === 'Product' && 'xl:flex'}`}>
      <div
        className={`${
          layout === 'Product' &&
          'xl:flex-1 xl:max-w-lg xl:w-[576px] 2xl:max-w-xl xl:order-2'
        }`}
      >
        <div ref={galleryImageRef} className="relative embla">
          <div className={css.emblaViewport} ref={viewportRef}>
            <div className={css.emblaContainer}>
              {items.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      css['slide' + index] ? css['slide' + index] : css.slide
                    }
                  >
                    <GalleryItem
                      css={css}
                      zoom={zoom!}
                      gallerySettings={gallerySettings}
                      index={index}
                      setLightBoxIsOpen={setLightBoxIsOpen}
                      item={item}
                      logos={logos}
                      priorityItems={priorityItems}
                      nItems={items.length}
                    />
                  </div>
                )
              })}
            </div>
          </div>

          <div className="flex items-center justify-start">
            <PrevButton
              layout={gallerySettings.carousel.buttonStyle}
              onClick={scrollPrev}
              enabled={prevBtnEnabled}
              visible={!!(btnVisible && gallerySettings.carousel.buttonVisible)}
            />
            <NextButton
              layout={gallerySettings.carousel.buttonStyle}
              onClick={scrollNext}
              enabled={nextBtnEnabled}
              visible={!!(btnVisible && gallerySettings.carousel.buttonVisible)}
            />
            {gallerySettings.dots && items.length > 1 && (
              <div className={css.emblaDotsWrapper}>
                <div className="flex flex-shrink-0 h-6 mt-3 embla__dots">
                  {scrollSnaps.map((_, index) => (
                    <DotButton
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => embla?.scrollTo(index)}
                      colorTheme="Default"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {gallerySettings.thumbs && items.length > 1 && (
        <div
          className={`${
            layout === 'Product' &&
            'xl:flex-shrink-0 xl:max-h-full xl:overflow-y-hidden'
          }`}
        >
          <div className={css.emblaThumbsWrapper}>
            <div
              ref={thumbsWrapperRef}
              className="flex justify-start mt-1 embla-thumb xl:mt-0 xl:mr-0"
            >
              <div
                className={`embla-thumb__viewport relative max-w-full overflow-x-hidden ${
                  layout === 'Product' && 'xl:w-full xl:overflow-y-hidden'
                }`}
                ref={thumbViewportRef}
              >
                <div
                  className={`embla-thumb__container relative flex select-none gap-x-1 ${
                    layout === 'Product' && 'xl:flex-col xl:gap-y-1 xl:h-full'
                  }`}
                >
                  {items.map((item, index) => {
                    if (!item.asset) return null
                    return (
                      <CarouselThumb
                        onClick={() => onThumbClick(index)}
                        selected={index === selectedIndex}
                        image={item.asset}
                        key={item.id}
                        layout={layout}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GalleryCarousel
