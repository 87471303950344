import React, { FC } from 'react'
import classNames from 'classnames'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import translate from 'utils/translate'

interface DotButtonProps {
  selected: boolean
  onClick: () => any
  colorTheme: 'Default' | 'Dark'
}

export const DotButton: FC<DotButtonProps> = ({
  selected,
  onClick,
  colorTheme,
}) => {
  const css = {
    buttonInner: `w-4 h-4 rounded-full ${
      selected
        ? 'bg-accent opacity-1 border-accent border-2'
        : 'bg-black opacity-10'
    }`,
  }

  if (colorTheme === 'Dark') {
    css.buttonInner = `rounded-full box-content ${
      selected
        ? 'w-1.5 h-1.5 bg-transparent border-accent border-2'
        : 'w-2 h-2 bg-accent'
    }`
  }

  return (
    <button
      className={`embla__dot outline-none relative cursor-pointer w-6 h-6 bg-transparent flex items-center justify-center`}
      type="button"
      onClick={onClick}
      aria-label={translate('dotButton')}
    >
      <span className={css.buttonInner}></span>
    </button>
  )
}

interface NavigationButtonProps {
  enabled: boolean
  onClick: () => any
  visible: boolean
  layout?: string
}

export const PrevButton: FC<NavigationButtonProps> = ({
  enabled,
  onClick,
  visible,
  layout,
}) => {
  const disabledButton = classNames({
    'opacity-0': !enabled,
    'pointer-events-none': !enabled,
    'cursor-auto': !enabled,
    hidden: !visible,
  })

  const css = {
    button: `embla__button outline-none embla__button--prev group w-10 h-10 lg:w-14 lg:h-14 p-1 lg:p-2.5 flex items-center justify-center transform -translate-y-1/2 -translate-x-1/2 lg:-translate-x-2/3 absolute top-[40%] left-0 rounded-full bg-primary text-white hover:opacity-75 transition-opacity duration-200 ${disabledButton}`,
  }

  if (layout === 'inside')
    css.button = `embla__button embla__button--prev outline-none group w-7 h-7 p-0.5 transform -translate-y-1/2 absolute top-[45%] left-4 lg:left-6 rounded-full bg-black bg-opacity-30 text-white hover:opacity-75 transition-opacity duration-200 ${disabledButton}`

  if (layout === 'lightbox')
    css.button = `embla__button embla__button--prev outline-none order-2 group w-6 h-6 transform rounded-full text-accent hover:opacity-75 transition-opacity duration-200 ${disabledButton}`

  return (
    <button
      aria-label={translate('previous')}
      className={css.button}
      onClick={onClick}
      disabled={!enabled}
    >
      <ChevronLeftIcon className="transform group-hover:-translate-x-0.5 group-hover:scale-105 transition-transform duration-200" />
    </button>
  )
}

export const NextButton: FC<NavigationButtonProps> = ({
  enabled,
  onClick,
  visible,
  layout,
}) => {
  const disabledButton = classNames({
    'opacity-0': !enabled,
    'pointer-events-none': !enabled,
    'cursor-auto': !enabled,
    hidden: !visible,
  })

  const css = {
    button: `embla__button embla__button--next outline-none group w-10 h-10 lg:w-14 lg:h-14 p-1 lg:p-2.5 flex items-center justify-center transform -translate-y-1/2 translate-x-1/2 lg:translate-x-2/3 absolute top-[40%] right-0 rounded-full bg-primary text-white hover:opacity-75 transition-opacity duration-200 ${disabledButton}`,
  }

  if (layout === 'inside')
    css.button = `embla__button embla__button--prev outline-none group w-7 h-7 p-0.5 transform -translate-y-1/2 absolute top-[45%] right-4 lg:right-6 rounded-full bg-black bg-opacity-30 text-white hover:opacity-75 transition-opacity duration-200 ${disabledButton}`

  if (layout === 'lightbox')
    css.button = `embla__button embla__button--next outline-none order-4 group w-6 h-6 transform rounded-full text-accent hover:opacity-75 transition-opacity duration-200 ${disabledButton}`

  return (
    <button
      aria-label={translate('next')}
      className={css.button}
      onClick={onClick}
      disabled={!enabled}
    >
      <ChevronRightIcon className="transform group-hover:translate-x-0.5 group-hover:scale-105 transition-transform duration-200" />
    </button>
  )
}
